<template>
  <div>
    <el-card class="marketing" v-if="show">
      <el-button :circle="true" icon="el-icon-my-freeCourse" style="padding: 0"></el-button>
      <p>领取</p>
      <p style="margin-bottom: 12px">免费教程</p>
      <el-button :circle="true" icon="el-icon-my-audition" style="padding: 0"></el-button>
      <p>预约</p>
      <p style="margin-bottom: 12px">免费试听</p>
      <el-button :circle="true" icon="el-icon-my-discounts" style="padding: 0"></el-button>
      <p>申请</p>
      <p style="margin-bottom: 12px">学费优惠</p>
      <el-button :circle="true" icon="el-icon-my-customerService" style="padding: 0"></el-button>
      <p style="margin-bottom: 12px">在线客服</p>
    </el-card>
    <el-button class="up" type="success" icon="el-icon-caret-top" circle v-show="show" @click="marketingShow"></el-button>
    <el-button class="outline" type="success" icon="el-icon-my-phone" style="left: 0" circle v-show="!show" @click="marketingShow"></el-button>
  </div>
</template>

<script>
export default {
  name: "MarketingSidebar",
  data() {
    return {
      icon: "",
      show: true
    }
  },
  mounted() {
    if (window.innerWidth<768){
      this.show = false
    }
  },
  methods:{
    marketingShow(){
      this.show=!(this.show);
    }
  }
}
</script>

<style scoped>
.marketing {
  position: fixed;
  width: 90px;
  height: 350px;
  left: 0;
  top: 20%;
  bottom: 0;
  text-align: center;
  background-color: rgba(222, 0, 0);
  border-color: #e17433;
  border-width: 4px;
  z-index: 999;
}

.up {
  position: fixed;
  left: 27px;
  top: calc(20% + 350px + 10px);
  background-color: #e17433;
  z-index: 999;
}
.outline{
  position: fixed;
  left: 27px;
  top: 50%;
  background-color: rgba(222, 0, 0);
  z-index: 999;
}

p {
  margin: 0 auto;
  color: rgb(255, 255, 255);
  font-size: 12px;
}
</style>
