import { getToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    id: '',
    username: '',
    avatar: '',
    nickname: '',
    phoneNumber: '',
    roles: [],
    permissions: []
  },
}

export default user
