<template>
  <div>
    <div class="chat-window" v-if="chatWindowShow">
      <div class="chat-header">
        <div class="avatar"></div>
        <div class="title">在线客服</div>
        <button class="minimize-btn" @click="minimize">x</button>
      </div>

      <div class="message-list" ref="messageList">
        <div v-for="(message, index) in messages" :key="index" class="message">
          <div v-if="message.from === 'agent'" class="agent-message">
            <div class="avatar"></div>
            <div class="content">
              <div class="meta">{{ message.sender }} {{ message.time }}</div>
              <div v-if="message.text" class="text">{{ message.text }}</div>
              <div v-if="message.images && message.images.length > 0" class="images">
                <div v-for="(image, idx) in message.images" :key="idx" class="image">
                  <img style="object-fit: none ;width: 100%;height: auto" :src="image.src" :alt="image.alt">
                </div>
              </div>
            </div>
          </div>
          <div v-else class="customer-message">
            <div class="content">
              <div class="meta">您 {{ message.time }}</div>
              <div class="text">{{ message.text }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="input-box">
        <input type="text" v-model="newMessage" @keyup.enter="sendMessage" placeholder="请输入您的留言...">
        <button @click="sendMessage">发送</button>
      </div>
    </div>

    <a @click="minimize">
      <el-button class="onlineCustomerService" circle round icon="el-icon-my-chat-window-customer-service"
                 v-if="onlineCustomerServiceShow"></el-button>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [],
      newMessage: '',
      chatWindowShow: true,
      onlineCustomerServiceShow: false,
      audio: null
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.chatWindowShow = false;
      this.onlineCustomerServiceShow = true;
    }
    // 初始化音频
    this.audio = new Audio('/prompt.mp3');
    // 页面加载时自动推送客服消息
    this.autoReply();
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const currentTime = this.getCurrentTime();
        this.messages.push({text: this.newMessage, from: 'customer', sender: '您', time: currentTime});
        this.autoReply(currentTime);
        this.newMessage = '';
        this.$nextTick(() => {
          this.scrollToBottom(); // 滚动到底部
        });
      }
    },
    autoReply(time = this.getCurrentTime()) {
      setTimeout(() => {
        // 推送客服消息
        this.messages.push({
          text: '领取学习资料/了解课程您可以关注我们的抖音账号/微信公众号/小红书/或者拨打客服电话联系我们17582990758',
          images: [
            {src: '/tiktokCode.png', alt: '抖音关注我们'},
            {src: '/weiChatCode.png', alt: '关注微信公共号留言给我们'}
          ],
          from: 'agent',
          sender: '客服',
          time: time
        });
        // 播放声音
        this.audio.play().catch((error) => {});
        // 滚动到底部
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }, 500);
    },
    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },
    scrollToBottom() {
      this.$refs.messageList.scrollTop = this.$refs.messageList.scrollHeight;
    },
    minimize() {
      // 最小化窗口
      this.chatWindowShow = !this.chatWindowShow;
      this.onlineCustomerServiceShow = !this.onlineCustomerServiceShow;
    }
  }
};
</script>

<style scoped>
.chat-window {
  width: 300px;
  height: 400px;
  background-color: #e5e5e5;
  border: 1px solid #ccc;
  overflow-y: auto;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  border-radius: 25px;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  background-image: url('/public/imgs/chatWindow/avatar.jpg');
  background-size: cover;
  background-position: center; /* 图片居中对齐 */
}
.agent-message .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  background-image: url('/public/imgs/chatWindow/avatar.jpg');
  background-size: cover;
  background-position: center;
  margin-right: 10px; /* 确保此值不会影响图片显示 */
  flex-shrink: 0; /* 避免 flex 布局压缩图片 */
}

.title {
  font-weight: bold;
}

.minimize-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}

.message-list {
  padding: 10px;
  flex: 1;
  overflow-y: auto;
}

.agent-message {
  display: flex;
  margin-bottom: 10px;
}

.agent-message .avatar {
  margin-right: 10px;
}

.customer-message {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.content {
  background-color: #e6f7ff;
  padding: 8px;
  border-radius: 8px;
}

.meta {
  font-size: 12px;
  color: #666;
}

.input-box {
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: flex-end;
}

.input-box input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-right: 5px;
}

.input-box button {
  padding: 8px 15px;
  background-color: #007bff;
  border: none;
  color: #fff;
  border-radius: 25px;
  cursor: pointer;
}

.onlineCustomerService {
  position: fixed;
  top: 30%;
  right: 20px;
  z-index: 999;
  background: linear-gradient(180deg, #3B4DFF 0%, #26BEF5 100%);
  border-width: 0;
}

</style>
