import JSEncrypt from 'jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJe8wenBZIJ2fdXUdtd2OiZWrMazEl9N\n' +
  'd/9JEo+RFKikIegByj25A3dP3ECJL5wJji9OpJJic1sJuaQf0eUy1BkCAwEAAQ=='

const privateKey = 'MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAl7zB6cFkgnZ91dR2\n' +
  '13Y6JlasxrMSX013/0kSj5EUqKQh6AHKPbkDd0/cQIkvnAmOL06kkmJzWwm5pB/R\n' +
  '5TLUGQIDAQABAkAxZ1nTnA33lFDgQC75dBr2xJ24jDFdKkH5sncCm4AYb+fnaeHs\n' +
  'Dxy9vfJ5phVNiry7Qoz/q5f9aBuBKeIiQQ8BAiEAx9oqGka7THqvhbFXY/ITpYAd\n' +
  'AHuGw2raB8I47xRI3/ECIQDCXg8+QR9YiIHpAREglfjz9kBALCiL6PyZXliU4STe\n' +
  'qQIhAJWR2WDA/vxF9YcBKn5KdhmuwAXUbQ9jsLmNyj5j2AaBAiAZp2SHqPzEKLBM\n' +
  '2rEyBdloCdaNIcbwQH4mmAdFDzpYkQIgVjbuAmtKn8UhYupmiusJJPjqwUPghQLa\n' +
  '/rI0cUcSgzQ='

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

