<template>
  <div class="submit-work-detail" v-if="SubmitWorkDetailShow">
    <div class="submit-work-detail-heard">
      <span>发布帖子</span>
      <el-popconfirm
          confirm-button-text='确定退出'
          cancel-button-text='不用了'
          icon="el-icon-info"
          icon-color="red"
          title="退出内容将被清空不会被保存，您确定要退出吗？"
          @confirm="closeSubmitWorkDetail"
      >
        <el-button class="submit-work-detail-close" icon="el-icon-my-close" slot="reference" circle></el-button>
      </el-popconfirm>
    </div>
    <hr>
    <div class="submit-work-detail-home">
      <el-form label-position="right" label-width="80px" :model="formLabelAlign">
        <el-form-item label="选择类别:">
          <el-select style="width: 100%" v-model="value" placeholder="请选择">
            <el-option
                v-for="item in categoryOptions"
                :key="item.keywords"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题:">
          <el-input v-model="formLabelAlign.title"></el-input>
        </el-form-item>
        <el-form-item label="内容:">
          <el-input
              type="textarea"
              :rows="8"
              maxlength="300"
              show-word-limit
              resize="none"
              v-model="formLabelAlign.detail"></el-input>
        </el-form-item>
        <el-form-item label="图片上传/视频:">
          <el-upload
              :auto-upload="true"
              :action="uploadUrl"
              :headers=headers
              :limit='9'
              list-type="picture-card"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
      </el-form>
      <el-popconfirm
          confirm-button-text='确定退出！'
          cancel-button-text='继续发布！'
          icon="el-icon-info"
          icon-color="red"
          title="您确定要退出吗？"
          @confirm="closeSubmitWorkDetail"
      >
        <el-button type="info" slot="reference">取消</el-button>
      </el-popconfirm>
      <el-button type="success" :loading="false" @click="submitWorks()" :disabled="submitWorksIsDisabled">发布
      </el-button>
    </div>
  </div>
</template>

<script>

import {getToken} from "@/utils/auth";

export default {
  data() {
    return {
      user: localStorage.loginInfo ? JSON.parse(localStorage.loginInfo) : null,
      SubmitWorkDetailShow: false,
      submitWorksIsDisabled: false,
      categoryOptions: [],
      formLabelAlign: {
        categoryId: null,
        categoryName: null,
        title: null,
        brief: null,
        coverUrl: null,
        keywords: null,
        detail: null,
        images: [],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      title: '',
      value: '',
      uploadUrl: this.BaseUrl.UPLOAD + "/common/upload",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
  },
  computed: {},
  methods: {
    reset() {
      this.formLabelAlign = {
        categoryId: null,
        categoryName: null,
        title: null,
        brief: null,
        coverUrl: null,
        keywords: null,
        detail: null,
        images: [],

      };
    },
    closeSubmitWorkDetail() {
      this.SubmitWorkDetailShow = false;
    },
    handleRemove(file, fileList) {

    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleError(err, file, fileList) {
      this.$message.error(err)
    },
    handleSuccess(response, file, fileList) {
      if (response.code === 200){
        this.formLabelAlign.images.push(response.fileName)
      }
    },
    submitWorks() {
      if (this.user === null) {
        this.$message.error("请先登录后在进行此操作")
        location.reload()
        return;
      }
      this.submitWorksIsDisabled = true
      let url = this.BaseUrl.ARTICLE_MANAGEMENT + '/offcialWebsite/userOffcialWebsiteArticle/add'
      this.formLabelAlign.categoryId = this.value
      if (this.formLabelAlign.images.length>0){
        this.formLabelAlign.coverUrl = this.formLabelAlign.images[0]
      }
      this.TokenAxios.post(url, this.formLabelAlign).then((response) => {
        if (response.data.code === 200) {
          this.submitWorksIsDisabled = false
          //重置表单
          this.reset();
          this.$message.success("作品发布成功！")
          this.closeSubmitWorkDetail();
        } else {
          this.submitWorksIsDisabled = false
          this.$message.error(response.data.message)
          //TODO 删除上传图片
        }
      }).catch((error) => {
      })
    },

    getCategories() {
      let url = this.BaseUrl.ARTICLE_MANAGEMENT + '/offcialWebsite/userOffcialWebsiteArticleCategory/list';
      this.SimpleAxios.get(url, this.categoryQueryParams)
          .then((response) => {
            if (response.data.code === 200) {
              this.categoryOptions = response.data.data
            } else {
              this.$message.error(response.data.message)
            }
          }).catch((error) => {
      })
    },
  },
  created() {
    this.getCategories();
  },

}
</script>

<style scoped>
.submit-work-detail {
  position: fixed;
  width: auto;
  min-width: 272px;
  max-height: 100%;
  overflow-y: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 30px 10px 10px;
  text-align: center;
  z-index: 999;
}

.submit-work-detail-heard {

}

.submit-work-detail-close {
  margin-left: 60%;
  border: 0;
}

</style>
