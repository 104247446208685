import axios from 'axios';


let instance = axios.create();

const SimpleAxios = {
    get(url, params) {
        return instance({
            method: 'GET',
            url: url,
            params: params
        });
    },
    post(url, data) {
        return instance({
            method: 'POST',
            url: url,
            data: data
        });
    }
}

export default SimpleAxios;
