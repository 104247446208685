<template>
  <el-card class="box-card">
    <el-row :gutter="5" style="min-width: 0">
      <el-col :span="3" :offset="3">
        <img src="/imgs/photoWall/photoWall_1-1.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_1-2.png"/>
      </el-col>
      <el-col :span="3" :offset="6">
        <img src="/imgs/photoWall/photoWall_1-3.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_1-4.png"/>
      </el-col>
    </el-row>

    <el-row :gutter="5">
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_2-1.png"/>
        <img src="/imgs/photoWall/photoWall_2-2.png"/>
      </el-col>
      <el-col :span="3">
        <img class="photoWallTitleName" src="/imgs/photoWall/photoWallTitleName_1.png"/>
      </el-col>
      <el-col :span="6">
        <img src="/imgs/photoWall/photoWallBig_1.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_2-3.png"/>
        <img src="/imgs/photoWall/photoWall_2-4.png"/>
      </el-col>
      <el-col :span="6">
        <img fit="fill" src="/imgs/photoWall/photoWallBig_2.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_2-5.png"/>
        <img src="/imgs/photoWall/photoWall_2-6.png"/>
      </el-col>
    </el-row>

    <el-row :gutter="5">
      <el-col :span="3">
        <el-row>
          <img src="/imgs/photoWall/photoWall_3-1.png"/>
        </el-row>
        <el-row/>
      </el-col>
      <el-col :span="3">
        <img class="photoWallTitleName" src="/imgs/photoWall/photoWallTitleName_2.png"/>
      </el-col>
      <el-col :span="3">
        <img class="photoWallTitleName" src="/imgs/photoWall/photoWallTitleName_3.png"/>
      </el-col>
      <el-col :span="3">
        <el-row>
          <img src="/imgs/photoWall/photoWall_3-2.png"/>
        </el-row>
        <el-row>
          <img src="/imgs/photoWall/photoWall_3-3.png"/>
        </el-row>
      </el-col>
      <el-col :span="6">
        <img src="/imgs/photoWall/photoWallBig_3.png"/>
      </el-col>
      <el-col :span="3">
        <img class="photoWallTitleName" src="/imgs/photoWall/photoWallTitleName_4.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_3-4.png"/>
      </el-col>
    </el-row>

    <el-row :gutter="5">
      <el-col :span="3" :offset="6">
        <img src="/imgs/photoWall/photoWall_4-1.png"/>
      </el-col>
      <el-col :span="6">
        <img src="/imgs/photoWall/photoWallBig_4.png"/>
      </el-col>
      <el-col :span="3">
        <img src="/imgs/photoWall/photoWall_4-2.png"/>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "photoWall",
  data() {
    return {}
  },
  mounted() {
    // 随机生成延迟时间，最长不超过4s
    function randomDelay() {
      return Math.floor(Math.random() * 4000) + 'ms';
    }

    // 获取所有具有该类的元素
    const elements = document.querySelectorAll('.photoWallTitleName');

// 为每个元素设置随机的动画延迟
    elements.forEach((element) => {
      element.style.animationDelay = randomDelay();
    });
  }
}
</script>

<style scoped>
.photoWallTitleName {
  animation-name: scaleDraw; /*关键帧名称*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  animation-iteration-count: infinite; /*动画播放的次数*/
  animation-duration: 3s; /*动画所花费的时间*/
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(1.0);
  }
}

img {
  height: auto;
  width: 100%;
  vertical-align: text-top;
}


</style>
