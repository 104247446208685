import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MarketingSidebar from "./components/marketing/MarketingSidebar.vue";
import ChatWindow from "./components/chat/ChatWindow.vue";
import BaseUrl from "@/http/BaseUrl";
import '/src/assets/css/icon/icon.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import axios from "axios"
import qs from 'qs'
import SimpleAxios from "@/http/SimpleAxios";
import TokenAxios from "@/http/TokenAxios";
import Cookies from 'js-cookie'

//引入Axios

Vue.prototype.axios = axios
//导入qs框架
Vue.prototype.qs = qs
Vue.component("ChatWindow",ChatWindow);
Vue.use(ElementUI);
Vue.component("MarketingSidebar",MarketingSidebar);
Vue.config.productionTip = false
Vue.prototype.BaseUrl = BaseUrl
Vue.prototype.SimpleAxios = SimpleAxios
Vue.prototype.TokenAxios =TokenAxios
Vue.prototype.Cookies = Cookies;

//重写方法解决跳转到同一路由报错问题
const myReplace = router.replace;
router.replace = function replace(location) {
  return myReplace.call(this,location).catch(err=>err);
};
const myPush = router.push;
router.push = function push(location) {
  return myPush.call(this,location).catch(err=>err);
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


